import React, {useEffect} from 'react';
import TemplateList from '../TemplateList/TemplateList';
import QuillEditor from './QuillEditor';
import './SendNotificationModal.css';
import {SendNotificationModalProps} from './types';
import useTemplateComposer from './useTemplateComposer';

const DialogHeader: React.FC<{isConfirmDisabled: boolean; onSaveTemplatePressed: () => void}> = ({
  onSaveTemplatePressed,
}) => (
  <div className="send-notification-dialog-header">
    <h3>Email Template Creator</h3>
    <div className="send-notification-dialog-footer">
      <button className="dialog-footer-submit-button" onClick={onSaveTemplatePressed}>
        Save Template
      </button>
    </div>
  </div>
);

const SendNotificationModal: React.FC<SendNotificationModalProps> = ({}) => {
  const {
    state,
    callApiToGetTemplateData,
    onSaveTemplatePressed,
    handleEmailSubjectChange,
    handleEmailContentChange,
    handleEmailTemplateNameChange,
    listOfTemplates,
    onDeleteTemplate,
  } = useTemplateComposer();

  useEffect(() => {
    callApiToGetTemplateData();
  }, []);

  useEffect(() => {
    console.log('isConfirmDisabled', state.isConfirmDisabled);
  }, [state.isConfirmDisabled]);

  const DialogContent = React.memo(() => {
    return (
      <div className="send-notification-dialog-content">
        <div>
          <div>
            <label style={{color: 'black'}}>Template Name</label>
            <input
              type="text"
              id="email-subject"
              placeholder="Enter template name"
              onChange={handleEmailTemplateNameChange}
              style={{
                width: '100%',
                height: '40px',
                padding: '8px',
                boxSizing: 'border-box',
                marginBottom: 8,
                marginTop: 4,
              }}
              onEnded={() => {
                console.log('ended');
              }}
              onEndedCapture={() => {
                console.log('ended');
              }}
            />
          </div>
          <label style={{color: 'black'}}>Email Subject</label>
          <input
            type="text"
            id="email-subject"
            placeholder="Enter email subject"
            onChange={handleEmailSubjectChange}
            style={{
              width: '100%',
              height: '40px',
              padding: '8px',
              boxSizing: 'border-box',
              marginBottom: 8,
              marginTop: 4,
            }}
            onEnded={() => {
              console.log('ended');
            }}
            onEndedCapture={() => {
              console.log('ended');
            }}
          />
        </div>

        <div style={{marginTop: 8, flex: 1, textAlign: 'left'}}>
          <label style={{color: 'black'}} htmlFor="quill-editor">
            Email Content
          </label>
          <QuillEditor
            // value={emailContentRef.current}
            variableKeys={state.variableKeys}
            onChange={handleEmailContentChange}
          />
        </div>
      </div>
    );
  });

  return (
    <div style={{backgroundColor: 'white', flex: 1}}>
      <DialogHeader isConfirmDisabled={state.isConfirmDisabled} onSaveTemplatePressed={onSaveTemplatePressed} />
      <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
        <div style={{flex: 1}}>
          <DialogContent />
        </div>
        <div style={{flex: 1, backgroundColor: 'white', maxWidth: 300, borderWidth: 1, borderColor: 'lightGrey'}}>
          <TemplateList templates={listOfTemplates} onDeleteTemplate={onDeleteTemplate} />
        </div>
      </div>
    </div>
  );
};

export default SendNotificationModal;
