import {
  GET_VISIT_DRAFTS_SUCCESS,
  SAVE_VISIT_DRAFT_SUCCESS,
  GET_SERVICES_SUCCESS,
  SET_ALL_VISIT_CHECKED,
  SET_VISIT_CHECKED,
  DELETE_VISIT_DRAFT,
  SAVE_OR_UPDATE_DRAFT,
  UPDATE_LOCAL_VISIT_DRAFTS,
  SAVE_OR_UPDATE_DRAFT_LOADING,
  API_DRAFTS_BY_CR,
  API_DRAFTS_UPSERT,
  API_GET_SERVICES,
  API_DRAFTS_DELETE,
  SET_DRAFTS_TYPE,
  SET_UPDATE_VISITS_DATA,
  API_MOB_DRAFTS_BY_CR,
  GET_MOB_VISIT_DRAFTS_SUCCESS,
  API_MOB_DRAFTS_UPSERT,
  API_MOB_DRAFTS_DELETE,
  API_ENT_DRAFTS_BY_CR,
  GET_ENT_VISIT_DRAFTS_SUCCESS,
  API_ENT_DRAFTS_UPSERT,
  API_ENT_DRAFTS_DELETE,
} from "../Constants";
import Api from "../Api";

export const DRAFT_TYPE_NEW = "dtn";
export const DRAFT_TYPE_UPDATE = "dtu";
export const DRAFT_TYPE_MOBILE = "dtm";
export const DRAFT_TYPE_ENTERPRISE = "dte";
export const DRAFT_TYPE_PENDING_PAYMENT = "dtpp";

export const getVisitDrafts = (cr_id: string) => {
  return (dispatch: any) => {
    Api.GET(
      `${API_DRAFTS_BY_CR}${cr_id}`,
      (response) => {
        if (response && response.data.success) {
          dispatch({
            type: GET_VISIT_DRAFTS_SUCCESS,
            savedVisitDrafts: response.data.data,
          });
        }
      },
      false,
      true,
      true
    );
  };
};

export const getMobileVisitDrafts = (cr_id: string, onSuccess: any) => {
  return (dispatch: any) => {
    Api.GET(`${API_MOB_DRAFTS_BY_CR}${cr_id}`, (response) => {
      if (response && response.data.success) {
        dispatch({
          type: GET_MOB_VISIT_DRAFTS_SUCCESS,
          savedVisitDrafts: response.data.data,
        });
        onSuccess && onSuccess();
      }
    });
  };
};

export const getEntVisitDrafts = (cr_id: string, onSuccess: any) => {
  return (dispatch: any) => {
    Api.GET(`${API_ENT_DRAFTS_BY_CR}${cr_id}`, (response) => {
      if (response && response.data.success) {
        dispatch({
          type: GET_ENT_VISIT_DRAFTS_SUCCESS,
          savedVisitDrafts: response.data.data,
        });
        onSuccess && onSuccess();
      }
    });
  };
};

// set drafts type to new visits, update or mobile visits
export const setDraftsType = (type: string, draftsId?: string) => {
  return (dispatch: any) => {
    dispatch({ type: SET_DRAFTS_TYPE, draftsId, result: type });
  };
};

// set drafts type to new visits, update or mobile visits
export const setUpdateVisitsData = (data: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_UPDATE_VISITS_DATA, result: data });
  };
};

// save visit drafts created by VisitDialog to redux store for listview
export const saveNewVisitDrafts = (visitDrafts: any[]) => {
  return (dispatch: any) => {
    dispatch({ type: SAVE_VISIT_DRAFT_SUCCESS, result: visitDrafts });
  };
};

// for new visits and mobile visit drafts
export const saveOrUpdateVisitDrafts = (
  cr_id: string,
  visitDrafts: any[],
  draftType: string
) => {
  const isMobile = draftType === DRAFT_TYPE_MOBILE;
  const isEnt = draftType === DRAFT_TYPE_ENTERPRISE;
  const API = isMobile
    ? API_MOB_DRAFTS_UPSERT
    : isEnt
    ? API_ENT_DRAFTS_UPSERT
    : API_DRAFTS_UPSERT;
  const reqDraftType = isMobile
    ? "mobileVisitDrafts"
    : isEnt
    ? "enterpriseVisitDrafts"
    : "visitDrafts";

  const reqBody: any = {};
  reqBody[reqDraftType] = visitDrafts;

  return (dispatch: any) => {
    dispatch({ type: SAVE_OR_UPDATE_DRAFT_LOADING, isLoading: true });
    Api.POST(`${API}${cr_id}`, reqBody, (response) => {
      if (response && response.data.success) {
        window.location.reload();
        dispatch({
          type: SAVE_OR_UPDATE_DRAFT,
          visitDrafts: response.data.visitDrafts,
          draftsType: draftType,
        });
      } else {
        dispatch({ type: SAVE_OR_UPDATE_DRAFT_LOADING, isLoading: false });
      }
    });
  };
};

export const getServices = () => {
  return (dispatch: any) => {
    Api.GET(
      API_GET_SERVICES,
      (response) => {
        const data = response && response.data;

        data &&
          dispatch({
            type: GET_SERVICES_SUCCESS,
            available_services: data.available_services || [],
            available_procedures: data.available_procedures || {},
            therapy: data.therapy || [],
            home_medical: data.home_medical || [],
            home_medical_procedures: data.home_medical_procedures || [],
            symptoms: data.symptoms || [],
            is_b2b_auto_assignment_enabled: data.is_b2b_auto_assignment_enabled,
            is_b2c_auto_assignment_enabled: data.is_b2c_auto_assignment_enabled
          });
      },
      false
    );
  };
};

export const setAllVisitChecked = (checked: boolean, draftType: string) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_ALL_VISIT_CHECKED,
      allChecked: checked,
      draftsType: draftType,
    });
  };
};

export const setVisitChecked = (
  visitDraftId: string,
  checked: boolean,
  draftType: string
) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_VISIT_CHECKED,
      visitIdToCheck: visitDraftId,
      checked: checked,
      draftsType: draftType,
    });
  };
};

export const deleteVisitDrafts = (
  visitDraftIds: Array<string>,
  isDeleteAll: boolean,
  draftType: string
) => {
  const isMobile = draftType === DRAFT_TYPE_MOBILE;
  const isEnt = draftType === DRAFT_TYPE_ENTERPRISE;
  const API = isMobile
    ? API_MOB_DRAFTS_DELETE
    : isEnt
    ? API_ENT_DRAFTS_DELETE
    : API_DRAFTS_DELETE;
  const reqDraftType = isMobile
    ? "mobile_draft_ids"
    : isEnt
    ? "enterprise_draft_ids"
    : "visit_draft_ids";

  const reqBody: any = {};
  reqBody[reqDraftType] = visitDraftIds;

  return (dispatch: any) => {
    Api.POST(API, reqBody, (response) => {
      if (response && response.data.success) {
        dispatch({
          type: DELETE_VISIT_DRAFT,
          deletedVisitDrafts: visitDraftIds,
          draftsType: draftType,
          isDeleteAll,
        });
      }
    });
  };
};

export const updateLocalVisitDrafts = (visits: any[], draftType: string) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_LOCAL_VISIT_DRAFTS,
      visitDraftUpdatedObjects: visits,
      draftsType: draftType,
    });
  };
};
