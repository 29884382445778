import {
  GET_VISIT_DRAFTS_SUCCESS,
  SAVE_VISIT_DRAFT_SUCCESS,
  GET_SERVICES_SUCCESS,
  SET_ALL_VISIT_CHECKED,
  SET_VISIT_CHECKED,
  DELETE_VISIT_DRAFT,
  CREATE_VISITS,
  SAVE_OR_UPDATE_DRAFT,
  UPDATE_LOCAL_VISIT_DRAFTS,
  SAVE_OR_UPDATE_DRAFT_LOADING,
  SET_UPDATE_VISITS_DATA,
  SET_DRAFTS_TYPE,
  GET_MOB_VISIT_DRAFTS_SUCCESS,
  GET_ENT_VISIT_DRAFTS_SUCCESS,
} from "../Constants";
import {
  DRAFT_TYPE_UPDATE,
  DRAFT_TYPE_MOBILE,
  DRAFT_TYPE_ENTERPRISE,
} from "../Actions/VisitDraftActions";
import { ADDED_VISITS } from "../Components/Dialogs/constants";
import { nanoid } from "nanoid";

type Action = {
  is_b2b_auto_assignment_enabled: boolean;
  is_b2c_auto_assignment_enabled: boolean;
  type: string;
  draftsId: string;
  savedVisitDrafts?: Array<object>;
  visitDrafts?: Array<any>;
  result?: any;
  visitDraftId?: string;
  serviceToRemove?: string;
  available_services?: Array<string>;
  available_procedures?: Array<string>;
  therapy?: Array<String>;
  home_medical?: Array<String>;
  home_medical_procedures?: Array<any>;
  symptoms?: Array<string>;
  visitIdToCheck?: string;
  allChecked?: boolean;
  checked?: boolean;
  editedVisitDraft?: string;
  deletedVisitDrafts?: Array<string>;
  visitDraftsToDelete?: any[];
  visitDraftUpdatedObjects?: any;
  isDeleteAll?: boolean;
  error?: string;
  isLoading?: boolean;
  draftsType?: string;
};

type State = {
  recipientStore?: any;
  draftsType?: string;
  draftsId?: string;
  savedVisitDrafts?: any;
  savedMobileDrafts?: any;
  savedEntDrafts?: any;
  updateVisitsData?: any;
  allChecked?: boolean;
};

const getDraftsType = (actionDraftsType: string | undefined): keyof State => {
  if(actionDraftsType === DRAFT_TYPE_MOBILE){
    return "savedMobileDrafts";
  };
  if(actionDraftsType === DRAFT_TYPE_ENTERPRISE){
    return "savedEntDrafts";
  };
  return "savedVisitDrafts";
};

export default (state: State = {}, action: Action) => {
  const actionDraftsType = action.draftsType;
  const draftsType: keyof State = getDraftsType(actionDraftsType);
  const stateDrafts =
    state.draftsType === DRAFT_TYPE_UPDATE
      ? state.updateVisitsData[ADDED_VISITS]
      : state[draftsType];

  switch (action.type) {
    case GET_VISIT_DRAFTS_SUCCESS:
      return {
        ...state,
        savedVisitDrafts: action.savedVisitDrafts,
        allChecked: action.allChecked || false,
      };
    case GET_MOB_VISIT_DRAFTS_SUCCESS:
      const mobileDrafts = action.savedVisitDrafts;
      //const vd = (v:any) => parseDate(v.visit_schedule.date_from).getTime();
      //(mobileDrafts || []).sort((a:any, b:any) => vd(a) - vd(b));
      return {
        ...state,
        savedMobileDrafts: mobileDrafts,
        allChecked: action.allChecked || false,
      };
    case GET_ENT_VISIT_DRAFTS_SUCCESS:
      const entDrafts = action.savedVisitDrafts;
      return {
        ...state,
        savedEntDrafts: entDrafts,
        allChecked: action.allChecked || false,
      };

    case SAVE_VISIT_DRAFT_SUCCESS:
      let { savedVisitDrafts = [] } = state;
      let draftToSave = action.result.map((draft: any, index: number) => {
        //generate a temp ID if there is no ID (Almost all operation in the web uses ID to identify draft)
        if (!draft._id) {
          //draft._id = new Date().getTime() + index;
          draft._id = nanoid();
        }
        return draft;
      });
      savedVisitDrafts = draftToSave;
      return {
        ...state,
        savedVisitDrafts,
      };
    case SAVE_OR_UPDATE_DRAFT:
      state[draftsType] = action.visitDrafts;
      return { ...state, savingDrafts: false };

    case SET_DRAFTS_TYPE:
      state.draftsType = action.result;
      state.draftsId = action.draftsId;
      return { ...state };

    case SET_UPDATE_VISITS_DATA:
      state.updateVisitsData = action.result;
      return { ...state };

    case GET_SERVICES_SUCCESS:
      const services = (
        action.available_services || []
      ).sort((a: any, b: any) => (a.name < b.name ? -1 : 1));
      const mealReq: any = services.find(
        (a: any) => a.name === "Meal Arrangement"
      );
      mealReq &&
        mealReq.options.sort((a: any, b: any) => (a.name < b.name ? -1 : 1));

      const procedures: any = action.available_procedures;
      procedures && (procedures["simple_nursing_procedures"] || []).sort();
      procedures && (procedures["complex_nursing_procedures"] || []).sort();

      return {
        ...state,
        services: services,
        available_procedures: procedures,
        therapy: (action.therapy || []).sort(),
        home_medical: (action.home_medical || []).sort(),
        home_medical_procedures: action.home_medical_procedures || [],
        symptoms: action.symptoms || [],
        is_b2b_auto_assignment_enabled: action.is_b2b_auto_assignment_enabled,
        is_b2c_auto_assignment_enabled: action.is_b2c_auto_assignment_enabled
      };
    case SET_ALL_VISIT_CHECKED:
      for (let k = stateDrafts.length; k--; ) {
        stateDrafts[k].selected = action.allChecked;
      }
      return { ...state, allChecked: action.allChecked };
    case SET_VISIT_CHECKED:
      for (let k = stateDrafts.length; k--; ) {
        if (stateDrafts[k]._id === action.visitIdToCheck) {
          stateDrafts[k].selected = action.checked;
        }
      }
      return { ...state };
    case DELETE_VISIT_DRAFT:
      const deletedDrafts = action.deletedVisitDrafts;
      if (deletedDrafts && deletedDrafts[0]) {
        const saveDeleteVisitDraft = state[draftsType].filter(
          (v: any) => !deletedDrafts.includes(v._id)
        );
        state[draftsType] = saveDeleteVisitDraft;
        if (action.isDeleteAll || saveDeleteVisitDraft.length === 0) {
          window.location.reload();
        }
      }
      return { ...state };
    case CREATE_VISITS:
      const { visitDraftsToDelete } = action;
      if (visitDraftsToDelete && visitDraftsToDelete[0]) {
        const draftIdsToDelete = visitDraftsToDelete.map(
          (draft: any) => draft._id
        );
        state[draftsType] = state[draftsType].filter(
          (draft: any) => !draftIdsToDelete.includes(draft._id)
        );
      }
      return { ...state, creatingVisit: true };
    case UPDATE_LOCAL_VISIT_DRAFTS:
      const { visitDraftUpdatedObjects } = action;

      // convert to hashmap
      const hashmap = visitDraftUpdatedObjects.reduce(
        (map: any, visit: any) => {
          map[visit._id] = visit;
          return map;
        },
        {}
      );

      state[draftsType] = state[draftsType].map((visitDraft: any) => {
        const fromMap = hashmap[visitDraft._id];
        return fromMap ? fromMap : visitDraft;
      });
      return { ...state };
    case SAVE_OR_UPDATE_DRAFT_LOADING:
      return { ...state, savingDrafts: action.isLoading };
    default:
      return state;
  }
};
