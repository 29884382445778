import React from 'react';
import {debounce, postMessage, setMessageListeners} from 'Util/Utilities';

export default class BaseElem<P, S> extends React.Component<P, S> {
  get app() {
    return window._app;
  }

  // returns true if the browser tab is visible
  // this is used to pause the additional api requests when page is not visible
  get isPageVisible() {
    const app = this.app;
    return !app || (app._isVisible && !document.hidden);
  }

  // delay in milliseconds
  async(fn: Function, delayMillis = 0): number {
    return window.setTimeout(fn.bind(this), delayMillis);
  }

  cancelAsync(handlerId: number) {
    clearTimeout(handlerId);
  }

  /*/ bubbles & composed by default true
  fire(eventName: string, params?: any, shouldBubble? : boolean, isComposed? : boolean, node?: any) {
    const yes = true;
    shouldBubble = shouldBubble === undefined ? yes : false;
    isComposed = isComposed === undefined ? yes : false;

    // shouldBubble === undef && (shouldBubble = yes);
    // isComposed === undef && (isComposed = yes);
    (node || this).dispatchEvent(new CustomEvent(eventName, { detail: params, bubbles: shouldBubble, composed: isComposed }));
  }*/

  /*
   * Toggles an HTML attribute on or off.
   *
   * @param {string} name HTML attribute name
   * @param {boolean} bool Boolean to force the attribute on or off.
   *    When unspecified, the state of the attribute will be reversed.
   * @param {Element} node Node to target.  Defaults to `this`.
   * @return {void}
   */
  toggleAttrib(name: string, toggle?: any, node: any = this) {
    if (arguments.length === 1 || toggle === null) {
      toggle = !node.hasAttribute(name);
    }
    if (toggle) {
      node.setAttribute(name, '');
    } else {
      node.removeAttribute(name);
    }
  }

  /**
   * Toggles a CSS class on or off.
   *
   * @param {string} name CSS class name
   * @param {boolean=} bool Boolean to force the class on or off.
   *    When unspecified, the state of the class will be reversed.
   * @param {Element=} node Node to target.  Defaults to `this`.
   * @return {void}
   */
  toggleClass(name: string, node: any, bool?: boolean) {
    node = /** @type {Element} */ node || this;
    if (arguments.length === 2) {
      bool = !node.classList.contains(name);
    }
    if (bool) {
      node.classList.add(name);
    } else {
      node.classList.remove(name);
    }
  }

  transform(transformText: string, node: any) {
    node = node || this;
    node.style.webkitTransform = transformText;
    node.style.transform = transformText;
  }

  /**
   * Consume the click event and stop its propagation
   * @param e Click Event object
   */
  eatClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    return true;
  };

  /**
   * Throttle multiple calls to be executed only once within the given `wait` time
   * @param func Function to be executed
   * @param wait Time in milliseconds
   * @param immediate pass `true` To call the function immediately
   */
  debounce(func: Function, wait: number, immediate?: boolean) {
    debounce(this, func, wait, immediate);
  }

  ///////////////// OBSERVER PATTERN /////////////////
  /**
   * Post a message with optional data
   * @param messageName
   * @param payload
   */
  post(messageName: string, payload?: any, delay?: number) {
    postMessage(messageName, payload, delay);
  }

  /**
   * Add listeners for specific messages
   * @param messageNames array of messageNames to listen to
   */
  listen(...messageNames: any[]) {
    this.setListeners(true, ...messageNames);
  }

  /**
   * Remove listeners
   * @param messageNames array of messageNames to unlisten
   */
  unlisten(...messageNames: any[]) {
    this.setListeners(false, ...messageNames);
  }

  /**
   * Add/Remove listeners for specific messages
   * @param messageNames array of messageNames to listen to
   */
  setListeners(enable: boolean, ...messageNames: any[]) {
    setMessageListeners(this, enable, ...messageNames);
  }

  /**
   * Triggered when a message is received,
   * override in child classes to handle the messages
   *
   * @param messageName Message name
   * @param payload any payload with the message
   */
  onMessage(messageName: string, payload?: any) {
    // TODO: override in child classes
  }
  ///////////////// END OBSERVER PATTERN /////////////////
}
