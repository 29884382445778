import Api, {showSnackbar} from 'Api';
import {
  API_GET_EMAIL_VARIABLES,
  API_GET_RECRUITMENT_EMAIL_TEMPLATE_CREATE,
  API_GET_RECRUITMENT_EMAIL_TEMPLATE_DELETE,
  API_GET_RECRUITMENT_EMAIL_TEMPLATE_LIST,
} from 'Constants';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Template, TemplateType} from './types';

const useTemplateComposer = () => {
  const [state, setState] = useState({
    activeTab: 'compose-email' as TemplateType,
    selectedTemplates: {} as {[key in TemplateType]?: string},
    isConfirmDisabled: true,
    listOfTemplates: [] as Template[],
    isApiLoadingForTemplates: false,
    emailContent: '',
    emailSubject: '',
    emailTemplateName: '',
    variableKeys: [],
  });

  const emailContentRef = useRef(state.emailContent);
  const emailSubjectRef = useRef(state.emailSubject);
  const emailTemplateNameRef = useRef(state.emailTemplateName);

  const availableTabs: TemplateType[] = ['whatsapp', 'compose-email'];

  useEffect(() => {
    setState(prev => ({
      ...prev,
      emailContent: emailContentRef.current,
      emailSubject: emailSubjectRef.current,
      emailTemplateName: emailTemplateNameRef.current,
      isConfirmDisabled: !emailContentRef.current || !emailSubjectRef.current || !emailTemplateNameRef.current,
    }));
  }, [emailContentRef.current, emailSubjectRef.current, emailTemplateNameRef.current]);

  const callApiToGetTemplateData = useCallback(() => {
    setState(prev => ({...prev, isApiLoadingForTemplates: true}));
    Api.GET(API_GET_RECRUITMENT_EMAIL_TEMPLATE_LIST, (resp, err) => {
      if (err) {
        console.error('Error fetching recruitment templates:', err);
      } else {
        setState(prev => ({
          ...prev,
          listOfTemplates: resp?.data?.templates || prev.listOfTemplates,
        }));
      }
      setState(prev => ({...prev, isApiLoadingForTemplates: false}));
    });

    Api.GET(API_GET_EMAIL_VARIABLES, (resp, err) => {
      if (err) {
        console.error('Error fetching email variables:', err);
      } else {
        setState(prev => ({
          ...prev,
          variableKeys: resp?.data?.variable_keys || prev.variableKeys,
        }));
      }
    });
  }, []);

  useEffect(() => {
    callApiToGetTemplateData();
  }, [callApiToGetTemplateData]);

  const handleTemplateChange = (templateTitle: string) => {
    const template = state.listOfTemplates.find(t => t.title === templateTitle);
    if (template) {
      setState(prev => ({
        ...prev,
        selectedTemplates: {...prev.selectedTemplates, [template.type]: templateTitle},
        isConfirmDisabled: false,
      }));
    }
  };

  const onSaveTemplatePressed = () => {
    const request = {
      template_name: emailTemplateNameRef.current,
      subject: emailSubjectRef.current,
      content: emailContentRef.current,
    };
    Api.POST(API_GET_RECRUITMENT_EMAIL_TEMPLATE_CREATE, request, resp => {
      if (resp?.data?.success) {
        showSnackbar(resp.data.message || 'Your template has been successfully', 'success');
      }
      callApiToGetTemplateData();
    });
  };

  const onDeleteTemplate = (templateId: string) => {
    Api.POST(API_GET_RECRUITMENT_EMAIL_TEMPLATE_DELETE, {template_id: templateId}, resp => {
      if (resp?.data?.success) {
        showSnackbar(resp.data.message || 'Template Deleted', 'success');
      } else {
        showSnackbar('Error in deleting Template', 'error');
      }
      callApiToGetTemplateData();
    });
  };

  const handleEmailSubjectChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    emailSubjectRef.current = e.target.value;
  }, []);

  const handleEmailContentChange = useCallback((content: string) => {
    emailContentRef.current = content;
  }, []);

  const handleEmailTemplateNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    emailTemplateNameRef.current = e.target.value;
  }, []);

  return {
    state,
    handleTemplateChange,
    onSaveTemplatePressed,
    handleEmailSubjectChange,
    handleEmailContentChange,
    handleEmailTemplateNameChange,
    availableTabs,
    callApiToGetTemplateData,
    listOfTemplates: state.listOfTemplates || [],
    onDeleteTemplate,
  };
};

export default useTemplateComposer;
