import Cookies from 'js-cookie';
import { GET, POST, showSnackbar } from "../Api";
import { API_AUTH_VALIDATE, API_AUTH_SESSION, API_AUTH_LOGOUT, UNDEF } from '../Constants';

export const validateToken = (token: string) => {
   return POST(API_AUTH_VALIDATE, {token})
     .then(response => {
       if (response.status === 200) {
         Cookies.set('userid', response.data.userId, { expires: 7 })
         return { success: true };
       } else {
         return { success: false };
       }
     }).catch((err)=>showSnackbar('Sorry, you have enter an invalid email or password', "error"));
};

export const validateSession = async () => {  
  let res = await GET(API_AUTH_SESSION);
  return res.data.success;
}

export const logout = async () => {
  let res = await GET(API_AUTH_LOGOUT);
  return res.data.success;
}



