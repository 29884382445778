import {
  GET_CR_DETAILS_SUCCESS,
  GET_STATES,
  GET_CR_CO_VWO_LIST,
} from "../Constants";
type Action = {
  type: string;
  careRecipient?: object;
  states?: any[];
  districts?: any[];
  // locationFields?: any[];
  entityList?: any[];
};

type State = {
  visits?: any;
  crList: any[];
  coList: any[];
  states: any[];
  vwoList: any[];
};

export default (
  state: State = {
    crList: [],
    coList: [],
    states: [],
    vwoList: [],
  },
  action: Action
) => {
  switch (action.type) {
    case GET_CR_DETAILS_SUCCESS:
      return { ...state, careRecipient: action.careRecipient };
    case GET_STATES:
      return { ...state, states: action.states };
    case GET_CR_CO_VWO_LIST:
      if (action.entityList && action.entityList.length > 0) {
        let { crList, coList, vwoList } = state;
        let currentCRList: any[] = [],
          currentCoList: any[] = [],
          currentVWOList: any[] = [];
        action.entityList.forEach((entity: any) => {
          switch (entity.type) {
            case "owner":
              currentCoList.push(entity);
              break;
            case "vwo":
              currentVWOList.push(entity);
              break;
            case "recipient":
              currentCRList.push(entity);
              break;
          }
        });
        crList = crList.concat(currentCRList);
        coList = coList.concat(currentCoList);
        vwoList = vwoList.concat(currentVWOList);
        return { ...state, crList, coList, vwoList };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
};
