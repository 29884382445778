import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from '@material-ui/core';
import {Close} from '@mui/icons-material';
import React from 'react';
import {Template} from '../SendNotificationModal/types';

function TemplateList({templates, onDeleteTemplate}: {templates: Template[]; onDeleteTemplate: (id: string) => void}) {
  const handleSelect = (id: string) => {
    console.log(`Template ${id} selected`);
  };

  return (
    <div>
      <Typography variant="h6" component="h2" gutterBottom>
        Templates
      </Typography>
      <div style={{maxHeight: 400, overflowY: 'auto'}}>
        <List>
          {templates.map(template => (
            <ListItem button key={template.template_id} onClick={() => handleSelect(template.template_id)}>
              <ListItemText primary={<Typography variant="body2">{template.template_name}</Typography>} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onDeleteTemplate(template.template_id)}>
                  <Close />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}

export default TemplateList;
