import {TextDropdown} from 'Components/TextDropdown';
import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './SendNotificationModal.css';

interface QuillEditorProps {
  value?: string;
  variableKeys: any[];
  onChange: (content: string) => void;
}

const QuillEditor: React.FC<QuillEditorProps> = ({value, variableKeys, onChange}) => {
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.getEditor().setContents(quillRef.current.getEditor().clipboard.convert(value));
    }
  }, [value]);

  useEffect(() => {
    const customButtonContainer = document.querySelector('.ql-custom-button');
    if (customButtonContainer) {
      const customView = (
        <div style={{marginTop: -4}}>
          <TextDropdown
            inpClassName="custom-button"
            useVirtualList
            itemHeight={40}
            popupMaxHeight="300px"
            width="30px"
            maxWidth="30px"
            popupWidth="400px"
            placeholder="  "
            dropdownColor="black"
            items={variableKeys.map((item: any) => item.title + ':  ' + '[' + item.key + ']')}
            onChange={(_: any, item: any) => {
              const v = variableKeys.map((item: any) => item.title + ':  ' + '[' + item.key + ']');
              const indx = v.indexOf(item);
              const aa = variableKeys[indx].key;
              handleCustomButton(aa.trim());
            }}
          />
        </div>
      );

      ReactDOM.render(customView, customButtonContainer);
    }
  }, []);
  const handleChange = (content: string) => {
    console.log(content);
    onChange(content);
  };

  const handleCustomButton = (key: string) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range) {
        editor.insertText(range.index, key);
      }
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'ordered'}, {list: 'bullet'}],
        ['link'],
        [{color: []}, {background: []}],
        [{'custom-button': ' '}],
      ],
      handlers: {
        'custom-button': handleCustomButton,
      },
    },
  };

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        value={value}
        style={{
          width: '100%',
          height: '300px',
          backgroundColor: 'white',
          flex: 1,
          paddingBottom: 40,
          color: 'black',
          marginTop: 8,
        }}
        onChange={handleChange}
        theme="snow"
        modules={modules}
      />
    </div>
  );
};

export default QuillEditor;
