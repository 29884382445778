export const STATUS_OPEN = 'Open';
export const STATUS_REQUESTED = 'Requested';
export const STATUS_CONFIRMED = 'Confirmed';
export const STATUS_PARTIALLY_CONFIRMED = 'Partially Confirmed';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_PARTIALLY_COMPLETED = 'Partially Completed';
export const STATUS_CANCELLED = 'Cancelled';
export const STATUS_NO_SHOW = 'Cancelled';
export const STATUS_EXPIRED = 'Expired';
export const MEDICAL_ESCORT = 'Medical Escort';
export const OTHERS = 'Others';
export const OTHER_FUNDING = 'Other Funding';
export const OTHER_FUNDING_TAX_FREE = 'Other Funding - Tax free';
export const COMPANIONSHIP = 'Companionship';
export const PHYSIOTHERAPY = 'Physiotherapy';
export const SPEECH_THERAPY = 'Speech Therapy';
export const OCCUPATIONAL_THERAPY = 'Occupational Therapy';
export const BASIC_CONSULTATION = 'Basic Consultation';
export const MEAL_ARRANGEMENT = 'Meal Arrangement';

export const MAX_CPS_FOR_VISIT = 20;

export const FLEXIBLE_VISIT_TIMING = ['Yes', 'No', 'Not Sure'];

export const MOBILITY_TYPES = [
  'Level A: Independent',
  'Level B: Mild Assistance',
  'Level C: Moderate Assistance',
  'Level D: Maximum Assistance',
  'Level E: Fully Dependent',
];

export const NURSE_REQUIREMENTS = ['Nurse Not Required', 'Nurse Requested', 'Nurse Required'];

export const BED_TYPES = [
  'Low Bed (<65cm)',
  'Standard Bed (~65cm)',
  'High Bed (>65cm)',
  'Hospital Bed (adjustable)',
  'Mattress Only (floor level)',
];

export const TRANSFER_TYPES = [
  {
    name: 'Standing Transfer',
    unselected_image_url:
      'https://testing-sg-api.smiley-unicorn.info/images/api/mobility/transfer_type/standing_transfer_unselected.png',
  },
  {
    name: 'Pivot Transfer',
    unselected_image_url:
      'https://testing-sg-api.smiley-unicorn.info/images/api/mobility/transfer_type/pivot_transfer_unselected.png',
  },
  {
    name: 'Manual Lifting',
    unselected_image_url:
      'https://testing-sg-api.smiley-unicorn.info/images/api/mobility/transfer_type/manual_lifting_unselected.png',
  },
  {
    name: 'Two-man Transfer',
    unselected_image_url:
      'https://testing-sg-api.smiley-unicorn.info/images/api/mobility/transfer_type/two-man_transfer_unselected.png',
  },
];

export const VISIT_BREAK_OPTIONS = ['No break', '0.5 hour', '1 hour'];

export const VISIT_SOURCE_OPTIONS = [{name: 'Call'}, {name: 'Email'}, {name: 'Mobile', type: 'disabled'}];

export const B2B_VISIT_SOURCE_OPTIONS = [
  {name: 'Webform in-app', type: 'disabled'},
  {name: 'Webform desktop', type: 'disabled'},
  {name: 'Typeform'},
  {name: 'Whatsapp'},
  {name: 'Email'},
  {name: 'Call'},
];

export const VISIT_SOURCE_DISABLED = ['Mobile', 'Webform in-app', 'Webform desktop'];

export const SURCHARGE_WAIVE_REASONS = ['B2B Visits', 'Others'];
export const SURCHARGE_DONOT_PAY_REASONS = ['Urgent surcharge was waived', 'Others'];

export const PAY_IN_APP_REASONS = [
  'Does not own a mobile phone',
  'Not a tech savvy person',
  'Does not understand English',
  'Others',
];

export const visit_schedule = 'visit_schedule',
  created_by = 'created_by',
  requested_by = 'requested_by',
  customer_requested_at = 'customer_requested_at',
  visit_location_address = 'visit_location_address',
  requested_services = 'requested_services',
  medical_conditions = 'medical_conditions',
  medical_escort_instructions = 'medical_escort_instructions',
  meal_requirements = 'meal_requirements',
  nursing_procedure = 'nursing_procedure',
  home_rehabilitation = 'home_rehabilitation',
  home_medical_details = 'home_medical_details',
  companionship_description = 'companionship_description',
  procedures = 'procedures',
  current_symptoms = 'current_symptoms',
  current_symptoms_details = 'current_symptoms_details',
  currently_on_medication = 'currently_on_medication',
  medication_details = 'medication_details',
  have_drug_allergy = 'have_drug_allergy',
  drug_allergy_details = 'drug_allergy_details',
  therapy_details = 'therapy_details',
  doctor_memo_available = 'doctor_memo_available',
  doctor_memo = 'doctor_memo',
  expected_outcome = 'expected_outcome',
  medical_escort_details = 'medical_escort_details',
  name = 'name',
  phone = 'phone',
  is_medical_appointment = 'is_medical_appointment',
  medical_appointment_location = 'medical_appointment_location',
  medical_appointment_time = 'medical_appointment_time',
  medical_appointment_detail = 'medical_appointment_detail',
  is_providing_transportation = 'is_providing_transportation',
  transportation_details = 'transportation_details',
  home_medical = 'home_medical',
  ambulant = 'ambulant',
  is_nurse_required = 'is_nurse_required',
  is_nurse_requested = 'is_nurse_requested',
  reason_for_nurse = 'reason_for_nurse',
  mobility_instruction = 'mobility_instruction',
  has_manual_transfer = 'has_manual_transfer',
  manual_transfer_help = 'manual_transfer_help',
  have_hoist = 'have_hoist',
  co_accept_2_cps = 'co_accept_2_cps',
  manual_transfer_help_details = 'manual_transfer_help_details',
  transfer_points = 'transfer_points',
  bed = 'bed',
  breaks = 'breaks',
  required_cp = 'required_cp',
  transfer_type = 'transfer_type',
  require_vital_sign = 'require_vital_sign',
  special_requests_instructions = 'special_requests_instructions',
  internal_ops_cr_note = 'internal_ops_cr_note',
  internal_ops_fulfillment_note = 'internal_ops_fulfillment_note',
  preferred_cps = 'preferred_cps',
  customer_request_origin = 'customer_request_origin',
  assigned_carepros = 'assigned_carepros',
  payment_breakdown = 'payment_breakdown',
  blob_urls = '_blobUrls',
  preferred_cps_list_by_service = 'preferred_cps_list_by_service',
  preferred_cps_option = 'preferred_cps_option',
  hard_soft_value_designation = 'hard_soft_value_designation',
  co_preferred_timing_obj = 'co_preferred_timing_obj';

export const APP_COUNTRY = process.env.REACT_APP_COUNTRY || 'Singapore';
export const isSG = APP_COUNTRY === 'Singapore';
export const isMY = APP_COUNTRY === 'Malaysia';
export const isAU = APP_COUNTRY === 'Australia';
export const APP_COUNTRY_CODE = isSG ? 'sg' : isMY ? 'my' : 'au';
export const APP_PHONE_CODE = isSG ? '+65' : isMY ? '+60' : '+61';
export const APP_TYPE = process.env.REACT_APP_TYPE || 'B2C';
export const isAppTypeB2B = APP_TYPE === 'B2B';
export const isAppTypeB2C = APP_TYPE === 'B2C';

// Remember to update this list when we launch in new City
export const AU_CITIES = [
  {name: 'Melbourne', id: 'RqLvjBS6L'},
  {name: 'Sydney', id: '7ebXqJmuw37sAs7ow'},
  {name: 'Brisbane', id: 'pPXBRu4JyomEgZbK5'},
  {name: 'Perth', id: '6GhkjhJp6ABDRsNjw'},
  {name: 'Adelaide', id: 'fCQHauHxsx85owwcq'},
];

export const STC_SHOW_ON_FE = [
  {name: 'Showing', _id: 'yes'},
  {name: 'Not Showing', _id: 'no'},
];

export const PIQ_FOMR_CONFIG_MOCKUP = {
  is_multi_step_form: true,
  image_url: 'https://homage-cdn.s3.ap-southeast-1.amazonaws.com/piq-banner-web.png',
  mobile_image_url: 'https://homage-cdn.s3.ap-southeast-1.amazonaws.com/piq-banner-mobile.png',
  additional_data: {name: 'Test', cp_id: 'Cl4my-6IUqf'},
  forms: [
    {
      name: 'Personal Information',
      rows: [
        {
          title: 'Street / Road Name',
          type: 'text',
          value: 'No. 123, Malala Lann',
          attributes: {width: '50%', 'mobile-width': '100%'},
          field_name: 'location_address.street_address',
        },
        {
          title: 'House / Block No.',
          type: 'text',
          attributes: {width: '50%', 'mobile-width': '100%'},
          value: 'Blk-45',
          field_name: 'location_address.block_number',
        },
        {
          title: 'Unit No.',
          type: 'text',
          attributes: {width: '50%', 'mobile-width': '100%'},
          value: '',
          field_name: 'location_address.unit_number',
        },
        {
          title: '*Postal Code',
          type: 'number_input',
          attributes: {required: true, minLength: 6, maxLength: 6},
          value: '330045',
          field_name: 'location_address.post_code',
        },
        {
          title: '*Gender',
          type: 'radio_box',
          value: 'Male',
          options: [
            {value: 'Male', label: 'Male'},
            {value: 'Female', label: 'Female'},
          ],
          field_name: 'personal_information.gender',
          attributes: {required: true},
        },
        {
          title:
            "*I agree to the collection, use and/or disclosure of my personal data to the extent necessary to process my application as per the terms in Homage's Privacy Policy.",
          type: 'privacy_policy_checkbox',
          field_name: 'piq_legal_declaration',
          value: true,
          attributes: {required: true},
        },
      ],
    },
    {
      name: 'Education',
      rows: [
        {
          title: '*Which language(s) do you speak fluently? (Select all that applies)',
          type: 'multi_select_standard',
          value: ['Bahasa Indonesia', 'Cantonese', 'Hainanese', 'Hakka', 'Hokkien'],
          field_name: 'personal_information.fluent_languages',
          options: [
            {value: 'Bahasa Indonesia', label: 'Bahasa Indonesia'},
            {value: 'Cantonese', label: 'Cantonese'},
            {value: 'English', label: 'English'},
            {value: 'Hainanese', label: 'Hainanese'},
            {value: 'Hakka', label: 'Hakka'},
            {value: 'Hokkien', label: 'Hokkien'},
            {value: 'Malay', label: 'Malay'},
            {value: 'Mandarin', label: 'Mandarin'},
            {value: 'Tamil', label: 'Tamil'},
            {value: 'Teochew', label: 'Teochew'},
          ],
          attributes: {required: true},
        },
        {
          title: '*Highest education attained (Select one)',
          type: 'dropdown',
          value: 'Post-Secondary (NITEC, Higher NITEC)',
          field_name: 'current_profession.education_level',
          options: [
            {value: 'No Qualification', label: 'No Qualification'},
            {value: 'Primary', label: 'Primary'},
            {value: 'Secondary (N Level)', label: 'Secondary (N Level)'},
            {value: 'Secondary (O Level)', label: 'Secondary (O Level)'},
            {value: 'Post-Secondary (A Level)', label: 'Post-Secondary (A Level)'},
            {value: 'Post-Secondary (NITEC, Higher NITEC)', label: 'Post-Secondary (NITEC, Higher NITEC)'},
            {value: 'Diploma', label: 'Diploma'},
            {value: 'Professional Qualification', label: 'Professional Qualification'},
            {value: "Bachelor's(Or Equivalent)", label: "Bachelor's (Or Equivalent)"},
            {value: 'Postgraduate Diploma (Or Equivalent)', label: 'Postgraduate Diploma (Or Equivalent)'},
            {
              value: "Master's and Doctorate(Or Equivalent)",
              label: "Master's and Doctorate (Or Equivalent)",
            },
          ],
          attributes: {required: true},
        },
        {
          title: '*Certification(s) attained (Select all that applies)',
          type: 'multi_select_standard',
          value: ['SNB', 'AIC Caregiver Certification', 'CPR', 'AED', 'BCLS', 'Swab-trained'],
          field_name: 'skills_qualifications.certifications',
          options: [
            {value: 'SNB', label: 'SNB'},
            {value: 'AIC Caregiver Certification', label: 'AIC Caregiver Certification'},
            {value: 'CPR', label: 'CPR'},
            {value: 'AED', label: 'AED'},
            {value: 'BCLS', label: 'BCLS'},
            {value: 'Swab-trained', label: 'Swab-trained'},
          ],
          attributes: {required: true},
        },
      ],
    },
    {
      name: 'Work Experience',
      rows: [
        {
          title: '*Do you have hospital ward experiences in Singapore? (Excluding internships/student attachments)',
          type: 'radio_box',
          value: true,
          field_name: 'skills_qualifications.has_ward_experience',
          job_type: 'Nurse',
          attributes: {required: true},
          options: [
            {label: 'Yes', value: true},
            {label: 'No', value: false},
          ],
        },
        {
          title: '*How many year(s) of hospital ward experience do you have?',
          type: 'year_month_text_input',
          year_value: 3,
          month_value: '',
          month_field_name: 'skills_qualifications.num_months_ward_experience',
          field_name: 'skills_qualifications.num_years_ward_experience',
          job_type: 'Nurse',
          attributes: {required: true},
          depends_on: {field_name: 'skills_qualifications.has_ward_experience', value: true},
        },
        {
          title: 'Which type(s) of hospital ward? (Select all that applies)',
          type: 'multi_select_standard',
          job_type: 'Nurse',
          value: ['Cardiology', 'Diagnostic', 'ENT', 'Endocrinology'],
          field_name: 'skills_qualifications.type_of_hospital_ward',
          options: [
            {label: 'Cardiology', value: 'Cardiology'},
            {label: 'Day Surgery', value: 'Day Surgery'},
            {label: 'Diagnostic', value: 'Diagnostic'},
            {label: 'ENT', value: 'ENT'},
            {label: 'Emergency', value: 'Emergency'},
            {label: 'Endocrinology', value: 'Endocrinology'},
            {label: 'Endoscopy', value: 'Endoscopy'},
            {label: 'Geriatrics', value: 'Geriatrics'},
            {label: 'ICU', value: 'ICU'},
            {label: 'Medical', value: 'Medical'},
            {label: 'Neurology', value: 'Neurology'},
            {label: 'Obstetrics & Gynecology', value: 'Obstetrics & Gynecology'},
            {label: 'Oncology', value: 'Oncology'},
            {label: 'Operating Theatre', value: 'Operating Theatre'},
            {label: 'Orthopedic', value: 'Orthopedic'},
            {label: 'Palliative', value: 'Palliative'},
            {label: 'Pediatrics', value: 'Pediatrics'},
            {label: 'Psychiatry', value: 'Psychiatry'},
            {label: 'Radiology', value: 'Radiology'},
            {label: 'Renal', value: 'Renal'},
            {label: 'Surgical', value: 'Surgical'},
            {label: 'Urology', value: 'Urology'},
          ],
          depends_on: {field_name: 'skills_qualifications.has_ward_experience', value: true},
        },
        {
          title: '*How many years of nursing experience do you have?',
          type: 'year_month_text_input',
          job_type: 'Nurse',
          year_value: 1,
          month_value: 1,
          month_field_name: 'skills_qualifications.num_months_nursing_experience',
          field_name: 'skills_qualifications.num_years_nursing_experience',
          attributes: {required: true},
        },
        {
          title: 'Please select all nursing procedure(s) you are experienced in.',
          type: 'multi_select_standard',
          job_type: 'Nurse',
          value: [
            'Simple Wound Dressing (Stage 2 pressure sores)',
            'Complex Wound Dressing (Post surgery I&D wounds - small cavities)',
            'Simple Wound Dressing (Post-surgery incisional wounds)',
          ],
          field_name: 'skills_qualifications.nursing_procedures',
          options: [
            {
              _id: '8aQHXNxFLrgpxrW2g',
              label: 'Complex Wound Dressing (Superficial broken skin covering 50% of the body e.g trunk.)',
              value: 'Complex Wound Dressing (Superficial broken skin covering 50% of the body e.g trunk.)',
            },
            {
              _id: 'CprQf4bTAdTSmEs9t',
              label: 'Simple Wound Dressing (Stage 2 pressure sores)',
              value: 'Simple Wound Dressing (Stage 2 pressure sores)',
            },
            {
              _id: 'FyHjMC3ZyeguG9LRP',
              label: 'Simple Wound Dressing (Superficial broken skin)',
              value: 'Simple Wound Dressing (Superficial broken skin)',
            },
            {
              _id: 'RmS5rTkCNBiEynA4o',
              label: 'Complex Wound Dressing (Post surgery I&D wounds - small cavities)',
              value: 'Complex Wound Dressing (Post surgery I&D wounds - small cavities)',
            },
            {
              _id: 'Rxp4Fc2m9dTSKmKyD',
              label: 'Simple Wound Dressing (Post-surgery incisional wounds)',
              value: 'Simple Wound Dressing (Post-surgery incisional wounds)',
            },
            {_id: 'S8B8bD89BxuBqHDRi', label: 'Peritoneal Dialysis', value: 'Peritoneal Dialysis'},
            {
              _id: 'aez58PZQkySWFnkhs',
              label: 'Complex Wound Dressing (Stage 3 and above pressure sore)',
              value: 'Complex Wound Dressing (Stage 3 and above pressure sore)',
            },
            {_id: '3X4ZS5RXR4Xn5QJJG', label: 'PEG Feeding', value: 'PEG Feeding'},
            {
              _id: 'eaqJvMx9t878cLTfD',
              label: 'Injections (Subcutaneous)',
              value: 'Injections (Subcutaneous)',
            },
            {
              _id: 'huMthYcexv9PEsPBB',
              label: 'Urine Bag Drainage And Change',
              value: 'Urine Bag Drainage And Change',
            },
            {_id: '23v5HyfnTkWMzDoBJ', label: 'Injections (PICC, CVP)', value: 'Injections (PICC, CVP)'},
            {
              _id: 'oAYiPkvD6iLrotsPn',
              label: 'Injections (Intramuscular)',
              value: 'Injections (Intramuscular)',
            },
            {
              _id: 'oeWufnXM8eom9pG7P',
              label: 'Injections (Port-A-Cath)',
              value: 'Injections (Port-A-Cath)',
            },
            {
              _id: 'uychPQm8ZET6vEHfX',
              label: 'Injections (Intravenous)',
              value: 'Injections (Intravenous)',
            },
            {_id: 'ZZDiZpEZrbgbsqzda', label: 'Oral Suctioning', value: 'Oral Suctioning'},
            {_id: '6tvo2o4WtvsyxqTCv', label: 'NGT Feeding', value: 'NGT Feeding'},
            {
              _id: 'enanikTrPGXdRraCQ',
              label: 'Medication Administration',
              value: 'Medication Administration',
            },
            {_id: 'SG4R6oa8RfoeEPiDo', label: 'Tracheostomy Suctioning', value: 'Tracheostomy Suctioning'},
            {_id: 'Mrcjj7LvQiZFHaRY8', label: 'Stoma Bag Drainage', value: 'Stoma Bag Drainage'},
            {_id: 'Ef4sWMqTSgny4qThx', label: 'Blood Glucose Check', value: 'Blood Glucose Check'},
            {_id: '5QjkK73hiSmqekBv6', label: 'Cannulation', value: 'Cannulation'},
            {
              _id: 'oojEbDRSyasKqS8w4',
              label: 'Change/Application of Urosheath',
              value: 'Change/Application of Urosheath',
            },
            {
              _id: '88d5FzX9EXKxo27Jn',
              label: 'Chest / Drains / Tube Dressing',
              value: 'Chest / Drains / Tube Dressing',
            },
            {
              _id: 'b5DkZ2ntioqzWpMi7',
              label: 'Dressing (IV Access Lines)',
              value: 'Dressing (IV Access Lines)',
            },
            {_id: 'onj7MNc6Wk2zcoE4n', label: 'Dressing (VAC)', value: 'Dressing (VAC)'},
            {_id: 'AGstNtWQvGLE6HTS3', label: 'Flushing of Long Lines', value: 'Flushing of Long Lines'},
            {_id: 'hjjuA8zWXvoisLqbE', label: 'Injections (Bolus)', value: 'Injections (Bolus)'},
            {
              _id: 'yqyoCyPZyZvkDXobN',
              label: 'Nephrostomy Tube Care/Dressing',
              value: 'Nephrostomy Tube Care/Dressing',
            },
            {_id: 'teoFin4RCMbPc5b29', label: 'Oxygen Therapy (BIPAP)', value: 'Oxygen Therapy (BIPAP)'},
            {_id: 'JfufXLe59DDKQrRDg', label: 'Oxygen Therapy (CPAP)', value: 'Oxygen Therapy (CPAP)'},
            {
              _id: 'hCDATNSimz4AdfB6z',
              label: 'Oxygen Therapy (Home Ventilator)',
              value: 'Oxygen Therapy (Home Ventilator)',
            },
            {_id: '76CGjfE2xJR94QCQh', label: 'Oxygen Therapy (Mask)', value: 'Oxygen Therapy (Mask)'},
            {_id: 'Wiabv2PeH5ujotbSm', label: 'PCN Care', value: 'PCN Care'},
            {_id: 'cR5wfosPjuzRBEFuz', label: 'Phlebotomy', value: 'Phlebotomy'},
            {
              _id: 'toGoxZcLSjeYAF94A',
              label: 'Removal of Stitches (Staples)',
              value: 'Removal of Stitches (Staples)',
            },
            {
              _id: 'kr8cLguH4X8mA9nFL',
              label: 'Removal of Stitches (Sutures)',
              value: 'Removal of Stitches (Sutures)',
            },
            {
              _id: 'gPnP6M2zYjaQxctXd',
              label: 'Stoma Bag and Wafer Change',
              value: 'Stoma Bag and Wafer Change',
            },
            {_id: 'pn2oTASX8PNuXrGeh', label: 'Tracheostomy Dressing', value: 'Tracheostomy Dressing'},
            {_id: 'c838KiksGYfZ3owNC', label: 'Urine Bag Drainage', value: 'Urine Bag Drainage'},
            {
              _id: 'HWbQXWBexhBzApfyG',
              label: 'Nasogastric Tube Reinsertion',
              value: 'Nasogastric Tube Reinsertion',
            },
            {
              _id: '2W7mgRuN48cCGJEZS',
              label: 'Urine Catheterisation (Female)',
              value: 'Urine Catheterisation (Female)',
            },
            {_id: 'KfKoPPnaqKTDY3tCB', label: 'Swab Test', value: 'Swab Test'},
            {_id: 'gqrZMqAi5TFtTJiGs', label: 'Swab Test (volunteer)', value: 'Swab Test (volunteer)'},
            {_id: 'ARBmQuFGvvZyr3ixg', label: 'Swab Assistance', value: 'Swab Assistance'},
            {
              _id: 'ZND3RTWAwsFBpYgh5',
              label: 'Swab Assistance (volunteer)',
              value: 'Swab Assistance (volunteer)',
            },
            {_id: 'QXSBnvZzydigY5JAL', label: 'Swab Co-ordination', value: 'Swab Co-ordination'},
            {
              _id: 'qT4JwQRZN3yccvCyw',
              label: 'Other nursing procedure - test',
              value: 'Other nursing procedure - test',
            },
            {_id: '59npxNqFgxFpgPFvo', label: 'JL test service', value: 'JL test service'},
          ],
        },
      ],
    },
    {
      name: 'Work Preferences',
      rows: [
        {
          title:
            '*How long do you expect to work with Homage? (Every contract with our partner organisation will be for a specified duration without any breaks.)',
          type: 'dropdown',
          value: '1 - 2 months',
          field_name: 'work_preferences.expected_work_duration',
          options: [
            {value: '<1 month', label: '<1 month'},
            {value: '1 - 2 months', label: '1 - 2 months'},
            {value: '3 - 6 months', label: '3 - 6 months'},
            {value: '> 6 months', label: '> 6 months'},
          ],
          attributes: {required: true},
        },
        {
          title: '*How many hours can you commit per week? ',
          type: 'dropdown',
          value: '>44 hours (5 - 6 days)',
          field_name: 'work_preferences.shift_preferences.hours_per_week',
          attributes: {required: true},
          options: [
            {value: '>44 hours (5 - 6 days)', label: '>44 hours (5 - 6 days)'},
            {value: '33 ~ 44 hours (4 - 5 days)', label: '33 ~ 44 hours (4 - 5 days)'},
            {value: '20 ~ 32 hours (3 - 4 days)', label: '20 ~ 32 hours (3 - 4 days)'},
            {value: '<20 hours (1 - 2 days)', label: '<20 hours (1 - 2 days)'},
          ],
        },
        {
          title: '*What is your availability? (Select all that applies)',
          type: 'multi_select_standard',
          value: ['Daytime (e.g., 8am - 5pm)', 'Afternoon (e.g.,12pm - 8pm)'],
          field_name: 'work_preferences.shift_preferences.availability',
          options: [
            {value: 'Daytime (e.g., 8am - 5pm)', label: 'Daytime (e.g., 8am - 5pm)'},
            {value: 'Afternoon (e.g.,12pm - 8pm)', label: 'Afternoon (e.g.,12pm - 8pm)'},
            {value: 'Night time (e.g., 7pm - 7am)', label: 'Night time (e.g., 7pm - 7am)'},
          ],
          attributes: {required: true},
        },
        {
          title: '*Are you open to working on weekends?',
          type: 'dropdown',
          value: 'both',
          field_name: 'work_preferences.shift_preferences.open_to_work_on_weekends',
          attributes: {required: true},
          options: [
            {value: 'both', label: 'I can work on both Saturdays and Sundays.'},
            {value: 'saturday', label: 'I can work on Saturdays only.'},
            {value: 'sunday', label: 'I can work on Sundays only.'},
            {value: 'none', label: 'I cannot work on the weekends.'},
          ],
        },
        {
          title: 'Any additional infomation? ',
          type: 'text',
          value: 'ewrwer',
          field_name: 'work_preferences.shift_preferences.additional_remarks',
        },
      ],
    },
    {
      name: 'Others',
      rows: [
        {
          title: '*Are you currently employed?',
          type: 'radio_box',
          value: null,
          field_name: 'current_employment_status',
          attributes: {required: true},
          options: [
            {label: 'Yes', value: 'Currently Employed'},
            {label: 'No', value: 'Unemployed'},
          ],
        },
        {
          title: '*How long is your notice period?',
          type: 'xs_number_input',
          unit: 'Week(s)',
          field_name: 'current_profession.notice_period',
          value: '',
          attributes: {required: true},
          depends_on: {field_name: 'current_employment_status', value: 'Currently Employed'},
        },
        {
          title: '*Do you have tattoos that cannot be covered? ',
          type: 'radio_box',
          value: null,
          field_name: 'personal_information.has_tattoo',
          attributes: {required: true},
          options: [
            {label: 'Yes', value: true},
            {label: 'No', value: false},
          ],
        },
        {
          title: '*Do you wear a hijab?',
          type: 'radio_box',
          value: null,
          field_name: 'personal_information.has_hijab',
          attributes: {required: true},
          options: [
            {label: 'Yes', value: true},
            {label: 'No', value: false},
          ],
        },
        {
          title:
            '*Would you be comfortable removing the hijab for specific procedures, as required by the partner organisation?',
          type: 'radio_box',
          value: null,
          field_name: 'personal_information.remove_hijab',
          attributes: {required: true},
          options: [
            {label: 'Yes', value: true},
            {label: 'No', value: false},
          ],
          depends_on: {field_name: 'personal_information.has_hijab', value: true},
        },
      ],
    },
  ],
};

export const CP_SIGNUP_ATTRIBUTES = {
  engagement_types: [
    {
      name: 'fl',
      value: 'Freelance',
    },
    {
      name: 'stc',
      value: 'Short-Term Contract',
    },
    {
      name: 'ft',
      value: 'Full-Time',
    },
  ],
  job_opportunities: [
    {
      name: 'hc',
      value: 'Home Care',
    },
    {
      name: 'fbc',
      value: 'Facility-Based Care',
    },
    {
      name: 'covid',
      value: 'Covid Assignments',
    },
    {
      name: 'ics',
      value: 'Interim Care Services',
    },
  ],
  role_types: [
    {
      name: 'rn',
      value: 'Registered Nurse',
    },
    {
      name: 'en',
      value: 'Enrolled Nurse',
    },
    {
      name: 'pca',
      value: 'Patient Care/Healthcare Assistant',
    },
    {
      name: 'ta',
      value: 'Therapy Assistant',
    },
    {
      name: 'pt',
      value: 'Therapist - Physio',
    },
    {
      name: 'st',
      value: 'Therapist - Speech',
    },
    {
      name: 'op',
      value: 'Therapist - Occupational',
    },
    {
      name: 'dr',
      value: 'Doctor',
    },
  ],
};
