import React from 'react';
import {withRouter} from 'react-router-dom';
import SendNotificationModal from './Components/SendNotificationModal/SendNotificationModal';
import './EmailTemplateComposer.css';

const EmailTemplateComposer = (props: any) => {
  return (
    <div className="cp-dashboard-container">
      <SendNotificationModal />
    </div>
  );
};

export default withRouter(EmailTemplateComposer);
