import {
  GET_CR_VISITS_SUCCESS,
  GET_CR_LONG_VISITS_SUCCESS,
  GET_CR_PAST_SUMMARY_SUCCESS,
  GET_CR_FUTURE_SUMMARY_SUCCESS,
  GET_PREVIOUS_VISIT,
  GET_PRIORITY_INBOX_VISIT_COUNT,
  GET_REASONS,
  GET_CANCEL_REASONS,
  GET_SIMILAR_VISITS,
  GET_UPCOMING_VISITS,
  UNDEF,
} from "../Constants";
import { getDurationString, parseDate } from "../Util/DateUtils";
import { postMessage } from "Util/Utilities";

type Action = {
  type: string;
  visits?: Array<object>;
  fullVisits?: Array<object>;
  pastSummary?: object;
  futureSummary?: object;
  previousVisit?: any;
  priorityInboxData?: any;
  priorityInboxVisitCount?: any;
  reasons?: any;
  cancel_reasons?: any;
  similarVisits?: any[];
  upcomingVisits?: any[];
};

type State = {
  visits?: any;
};

export default (state: State = {}, action: Action) => {
  switch (action.type) {
    case GET_CR_VISITS_SUCCESS:
      if (action.visits && action.visits.length > 0) {
        action.visits = action.visits.map((visit: any) => {
          const duration = getDurationString(
            parseDate(visit.startDate),
            parseDate(visit.endDate),
            true
          );
          let title = "";
          if (visit.adl) {
            title += "ADL";
          }
          if (visit.simpleNursing) {
            if (title.length > 0) title += " + ";
            title += "Simple Nursing";
          }
          if (visit.complexNursing) {
            if (title.length > 0) title += " + ";
            title += "Complex Nursing";
          }
          if (visit.therapy) {
            title += "Home Therapy";
          }
          if (visit.homeMedical) {
            title += "Home Medical";
          }
          visit.title = `${title} (${duration})`;
          return visit;
        });
      }
      postMessage(GET_CR_VISITS_SUCCESS, UNDEF, 1);
      return {
        ...state,
        visits: action.visits,
      };
    case GET_CR_LONG_VISITS_SUCCESS:
      if (action.fullVisits) {
        action.fullVisits = action.fullVisits.map((visit: any) => {
          const duration = getDurationString(
            parseDate(visit.visit_schedule.date_from),
            parseDate(visit.visit_schedule.date_to),
            true
          );
          visit.title = `${visit.status.visit} Visit (${duration})`;
          return visit;
        });
      }
      return {
        ...state,
        fullVisits: action.fullVisits,
      };
    case GET_CR_PAST_SUMMARY_SUCCESS:
      return {
        ...state,
        pastSummary: action.pastSummary,
      };
    case GET_CR_FUTURE_SUMMARY_SUCCESS:
      return {
        ...state,
        futureSummary: action.futureSummary,
      };
    case GET_REASONS:
      return {
        ...state,
        reasons: action.reasons,
      };
    case GET_CANCEL_REASONS:
      return {
        ...state,
        cancel_reasons: action.cancel_reasons,
      };
    case GET_SIMILAR_VISITS: {
      const vd = (v: any) => parseDate(v.start_date).getTime();
      const sorted = (action.similarVisits || []).sort(
        (a: any, b: any) => vd(a) - vd(b)
      );
      return {
        ...state,
        similarVisits: sorted,
      };
    }
    case GET_UPCOMING_VISITS: {
      const vd = (v: any) => parseDate(v.start_date).getTime();
      const sorted = (action.upcomingVisits || []).sort(
        (a: any, b: any) => vd(a) - vd(b)
      );
      return {
        ...state,
        upcomingVisits: sorted,
      };
    }
    case GET_PREVIOUS_VISIT:
      let previousPricings: any[] = [];
      const prevVisit = action.previousVisit;
      const adl_pricing_packages = "adl_pricing_packages";
      const procedure_pricing_packages = "procedure_pricing_packages";
      const nursing_procedure = "nursing_procedure";

      if (
        prevVisit &&
        (prevVisit[adl_pricing_packages] ||
          prevVisit[procedure_pricing_packages])
      ) {
        previousPricings = [].concat(
          prevVisit[adl_pricing_packages],
          prevVisit[procedure_pricing_packages]
        );
      }

      if (prevVisit && Array.isArray(prevVisit[nursing_procedure])) {
        prevVisit[nursing_procedure] = prevVisit[nursing_procedure].map(
          (prod: any) => {
            if (prod && !prod["name"] && !prod["quantity"]) {
              return {
                name: prod,
                quantity: 1,
              };
            } else {
              return prod;
            }
          }
        );
      }
      return {
        ...state,
        previousVisit: prevVisit,
        previousPricings,
      };
    case GET_PRIORITY_INBOX_VISIT_COUNT:
      return {
        ...state,
        priorityInboxVisitCount: action.priorityInboxVisitCount,
      };
    default:
      return state;
  }
};
