import React, { useMemo } from "react";
import { UNDEF } from "../Constants";
import { _attr } from "Util/Utilities";

type StyleProps = {
  /**
   * Position relative
   */
  relative?: boolean;

  /**
   * display: none
   */
  gone?: boolean;

  /**
   * display: block
   */
  block?: boolean;

  /**
   * visibility: hidden
   */
  invisible?: boolean;

  /**
   * Display inline block
   */
  inline?: boolean;

  /**
   * View width
   */
  width?: number | string;

  /**
   * Minimum width
   */
  minW?: number | string;

  /**
   * Maximum width
   */
  maxW?: number | string;

  /**
   * View Height
   */
  height?: number | string;

  /**
   * Minimum height
   */
  minH?: number | string;

  /**
   * Maximum height
   */
  maxH?: number | string;

  /**
   * Opacity
   */
  opacity?: number | string;

  /**
   * View both width/height
   */
  size?: number | string;

  /**
   * Horizontal layout
   */
  h?: boolean;

  /**
   * Vertical layout
   */
  v?: boolean;

  /**
   * Margin
   */
  margin?: number | string;

  /**
   * Margin horizontal
   */
  mh?: number | string;

  /**
   * Margin vertical
   */
  mv?: number | string;

  /**
   * Margin top
   */
  mt?: number | string;

  /**
   * Margin bottom
   */
  mb?: number | string;

  /**
   * Margin left
   */
  ml?: number | string;

  /**
   * Margin right
   */
  mr?: number | string;

  /**
   * Padding
   */
  p?: number | string;

  /**
   * Padding horizontal
   */
  ph?: number | string;

  /**
   * Padding vertical
   */
  pv?: number | string;

  /**
   * Padding top
   */
  pt?: number | string;

  /**
   * Padding bottom
   */
  pb?: number | string;

  /**
   * Padding left
   */
  pl?: number | string;

  /**
   * Padding right
   */
  pr?: number | string;

  /**
   * flex: 1
   */
  g?: boolean;

  /**
   * flex-wrap: wrap
   */
  w?: boolean;

  /**
   * Absolute left: position
   */
  absL?: number | string;

  /**
   * Absolute right: position
   */
  absR?: number | string;

  /**
   * Absolute bottom: position
   */
  absB?: number | string;

  /**
   * Absolute top: position
   */
  absT?: number | string;

  /**
   * align items center (for horizontal layout)
   */
  vc?: boolean;

  /**
   * Align items center (for vertical layout)
   */
  hc?: boolean;

  /**
   * Align items start
   */
  flexStart?: boolean;

  /**
   * Align items end
   */
  flexEnd?: boolean;

  /**
   * Background color
   */
  bg?: any;

  /**
   * 100% width
   */
  w100?: boolean;

  /**
   * Border radius
   */
  br?: number;

  /**
  * Border width
  */
  bw?: number;

  /**
   * Border color
   */
  bc?: string;

  /**
   * This sets overflow: "hidden"
   */
  noOverflow?: boolean;

  /**
   * Auto overflow y
   */
  overflowY?: boolean;

  /**
   * Auto overflow x
   */
  overflowX?: boolean;

  /**
   * is-selected attribute
   */
  isSelected?: boolean;

  /**
   * Sets cursor: pointer;
   */
  pointer?: boolean;

  /**
   * Class name
   */
  className?: string;
};

type ImgProps = {
  /**
   * Image source property
   */
  src?: any;
};

type TxtProps = {
  /**
   * Regular font
   */
  r?: boolean;

  /**
   * Medium font
   */
  m?: boolean;

  /**
   * Semi Bold font
   */
  sb?: boolean;

  /**
   * Bold font
   */
  b?: boolean;

  /**
   * Light font
   */
  l?: boolean;

  /**
   * Text color
   */
  color?: string;

  /**
   * Font size
   */
  fs?: number;

  /**
   * Line height
   */
  lineHeight?: any;

  /**
   * Text align "center" | "left" | "right"
   */
  textAlign?: "center" | "left" | "right";
};

type AllProps = StyleProps &
  ImgProps &
  TxtProps & {
    style?: any; // Additional styling (Note: don't dynamically create style on each render)
    children?: any;
    onClick?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;

    /**
     * Assign Ref to view
     */
    vref?: any;
  };

export const Div = React.memo(
  ({
    vref,
    relative,
    inline,
    gone,
    block,
    invisible,
    width,
    height,
    size,
    margin,
    noOverflow,
    overflowX,
    overflowY,
    className,
    minH,
    maxH,
    minW,
    maxW,
    opacity,
    pointer,
    h,
    v,
    mh,
    mv,
    mt,
    mb,
    ml,
    mr,
    p,
    ph,
    pv,
    pt,
    pb,
    pl,
    pr,
    g,
    w,
    vc,
    hc,
    fs,
    flexStart,
    flexEnd,
    isSelected,
    w100,
    src,
    br,
    bw,
    bc,
    color,
    lineHeight,
    textAlign,
    r,
    m,
    b,
    sb,
    l,
    bg,
    absL,
    absT,
    absR,
    absB,
    onClick,
    onMouseEnter,
    onMouseLeave,
    style,
    children,
  }: AllProps) => {
    const isImg = !!src;
    //const isTxt = !!fs || typeof children === "string";

    const contStyle = useMemo(() => {
      let s: any = {};

      relative && (s = { ...s, position: "relative" });
      inline && (s = { ...s, display: "inline-block" });
      gone && (s = { ...s, display: "none" });
      block && (s = { ...s, display: "block" });
      invisible && (s = { ...s, visibility: "hidden" });
      width !== UNDEF && (s = { ...s, width: width });
      height !== UNDEF && (s = { ...s, height: height });
      size !== UNDEF && (s = { ...s, width: size, height: size });

      h && (s = { ...s, display: "flex", flexDirection: "row" });
      w && (s = { ...s, flexWrap: "wrap" });
      v && (s = { ...s, display: "flex", flexDirection: "column" });
      g && (s = { ...s, flexGrow: 1 });
      vc && (s = { ...s, alignItems: "center" });
      hc && (s = { ...s, justifyContent: "center" });
      flexStart && (s = { ...s, alignItems: "flex-start" });
      flexEnd && (s = { ...s, alignItems: "flex-end" });
      w100 && (s = { ...s, width: "100%" });
      noOverflow && (s = { ...s, overflow: "hidden" });
      overflowX && (s = { ...s, overflowX: "auto" });
      overflowY && (s = { ...s, overflowY: "auto" });
      pointer && (s = { ...s, cursor: "pointer" });

      (absT !== UNDEF || absR !== UNDEF || absB !== UNDEF || absL !== UNDEF) &&
        (s = { ...s, position: "absolute" });
      absL !== UNDEF && (s = { ...s, left: absL });
      absT !== UNDEF && (s = { ...s, top: absT });
      absR !== UNDEF && (s = { ...s, right: absR });
      absB !== UNDEF && (s = { ...s, bottom: absB });

      minW !== UNDEF && (s = { ...s, minWidth: minW });
      maxW !== UNDEF && (s = { ...s, maxWidth: maxW });
      minH !== UNDEF && (s = { ...s, minHeight: minH });
      maxH !== UNDEF && (s = { ...s, maxHeight: maxH });
      opacity !== UNDEF && (s = { ...s, opacity });

      margin !== UNDEF && (s = { ...s, margin: margin });
      mh !== UNDEF && (s = { ...s, marginLeft: mh, marginRight: mh });
      mv !== UNDEF && (s = { ...s, marginTop: mv, marginBottom: mv });
      ph !== UNDEF && (s = { ...s, paddingLeft: ph, paddingRight: ph });
      pv !== UNDEF && (s = { ...s, paddingTop: pv, paddingBottom: pv });
      p !== UNDEF && (s = { ...s, padding: p });

      mt !== UNDEF && (s = { ...s, marginTop: mt });
      pt !== UNDEF && (s = { ...s, paddingTop: pt });

      mr !== UNDEF && (s = { ...s, marginRight: mr });
      pr !== UNDEF && (s = { ...s, paddingRight: pr });

      mb !== UNDEF && (s = { ...s, marginBottom: mb });
      pb !== UNDEF && (s = { ...s, paddingBottom: pb });

      ml !== UNDEF && (s = { ...s, marginLeft: ml });
      pl !== UNDEF && (s = { ...s, paddingLeft: pl });

      bg && (s = { ...s, backgroundColor: bg });
      br && (s = { ...s, borderRadius: br });
      bc &&
        (s = { ...s, borderColor: bc, borderWidth: 1, borderStyle: "solid" });
      bc && bw &&
        (s = { ...s, borderColor: bc, borderWidth: bw, borderStyle: "solid" });

      // Text props
      //if (isTxt) {
      color && (s = { ...s, color: color });
      fs && (s = { ...s, fontSize: fs });
      lineHeight && (s = { ...s, lineHeight: lineHeight });
      //s = { ...s, ...cs.n };
      r && (s = { ...s, fontWeight: 400 });
      m && (s = { ...s, fontWeight: 500 });
      sb && (s = { ...s, fontWeight: 600 });
      b && (s = { ...s, fontWeight: 900 });
      l && (s = { ...s, fontWeight: 300 });
      textAlign && (s = { ...s, textAlign: textAlign });
      //}

      return { ...s, ...style };
    }, [
      h,
      v,
      size,
      w100,
      minH,
      minW,
      relative,
      inline,
      gone,
      block,
      invisible,
      width,
      height,
      maxW,
      maxH,
      opacity,
      pointer,
      margin,
      mh,
      mv,
      mt,
      mb,
      ml,
      mr,
      p,
      ph,
      pv,
      pt,
      pb,
      pl,
      pr,
      g,
      w,
      vc,
      hc,
      flexStart,
      flexEnd,
      color,
      fs,
      lineHeight,
      textAlign,
      r,
      m,
      b,
      sb,
      l,
      bg,
      br,
      bw,
      bc,
      absL,
      absT,
      absR,
      absB,
      noOverflow,
      overflowX,
      overflowY,
      style,
    ]);

    return isImg ? (
      <img
        ref={vref}
        className={className}
        alt=""
        style={contStyle}
        src={src}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        is-selected={_attr(isSelected)}
      />
    ) : (
      <div
        ref={vref}
        className={className}
        style={contStyle}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        is-selected={_attr(isSelected)}
      >
        {children}
      </div>
    );
  }
);

/**
 * Image component
 * Example: <Img src="/abc.png" size={20} ml={16}/>
 */
export const Img = Div;

/**
 * Text component
 * Default to Avenir regular font
 * Example: <Txt color="red" fontSize={20} ml={16}>Hello world</Txt>
 */
export const Txt = Div;
